import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../components/auth/Login.vue";
import Forgotpassword from "../components/auth/Forgotpassword.vue";
import Resetpassword from "../components/auth/Resetpassword.vue";
import MainLayout from "../components/layout/Layout.vue";
import Dashboard from "../components/Dashboard.vue";
import Profile from "../components/Profile.vue";
import ChangePassword from "../components/ChangePassword.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/forgot-password",
    name: "Forgotpassword",
    component: Forgotpassword,
    meta: {
      title: "Forgot password",
    },
  },
  {
    path: "/reset-password/:id/:token",
    name: "Resetpassword",
    component: Resetpassword,
    meta: {
      title: "Reset password",
    },
  },
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "profile",
        name: "Profile",
        component: Profile,
        meta: {
          title: "Profile",
        },
      },
      {
        path: "change-password",
        name: "ChangePassword",
        component: ChangePassword,
        meta: {
          title: "ChangePassword",
        },
      },
      {
        path: "/vendor/add",
        name: "AddVendor",
        meta: {
          title: "Add Vendor",
        },
        component: () => import("../components/vendor/Add"),
      },
      {
        path: "/vendor/edit/:id",
        name: "EditVendor",
        meta: {
          title: "Edit Vendor",
        },
        component: () => import("../components/vendor/Edit"),
      },
      {
        path: "/vendor/details/:vendor_id",
        name: "VendorDetails",
        meta: {
          title: "Vendor Details",
        },
        component: () => import("../components/vendor/Details"),
      },
      {
        path: "/vendor/add_object/:vendor_id",
        name: "AddVendorObject",
        meta: {
          title: "Add Vendor Object",
        },
        component: () => import("../components/vendor/object/Add"),
      },
      {
        path: "/vendor/edit_object/:id",
        name: "EditVendorObject",
        meta: {
          title: "Edit Object",
        },
        component: () => import("../components/vendor/object/Edit"),
      },
      {
        path: "/vendor/resource/:vendor_id",
        name: "ResourceList",
        meta: {
          title: "Resource List",
        },
        component: () => import("../components/vendor/resources/List"),
      },
      {
        path: "/vendor/add_resource/:vendor_id",
        name: "AddVendorResource",
        meta: {
          title: "Add Vendor Resource",
        },
        component: () => import("../components/vendor/resources/Add"),
      },
      {
        path: "/vendor",
        name: "VendorList",
        meta: {
          title: "Vendor List",
        },
        component: () => import("../components/vendor/List"),
      },
      {
        path: "/attendees",
        name: "AttendeeList",
        meta: {
          title: "Attendee List",
        },
        component: () => import("../components/attendee/List"),
      },
      {
        path: "/session",
        name: "SessionList",
        meta: {
          title: "Session List",
        },
        component: () => import("../components/session/List"),
      },
      {
        path: "/session/add",
        name: "AddSession",
        meta: {
          title: "Add Session",
        },
        component: () => import("../components/session/Add"),
      },
      {
        path: "/session/edit/:id",
        name: "EditSession",
        meta: {
          title: "Edit Session",
        },
        component: () => import("../components/session/Edit"),
      },
      {
        path: "/object",
        name: "ObjectList",
        meta: {
          title: "Object List",
        },
        component: () => import("../components/object/List"),
      },
      {
        path: "/object/add",
        name: "AddObject",
        meta: {
          title: "Add Object",
        },
        component: () => import("../components/object/Add"),
      },
      {
        path: "/object/edit/:id",
        name: "EditObject",
        meta: {
          title: "Edit Object",
        },
        component: () => import("../components/object/Edit"),
      },
      {
        path: "/attendee/edit/:id",
        name: "EditAttendee",
        meta: {
          title: "Edit Attendee",
        },
        component: () => import("../components/attendee/Edit"),
      },
      {
        path: "/attendee/add",
        name: "AddAttendee",
        meta: {
          title: "Add Attendee",
        },
        component: () => import("../components/attendee/Add"),
      },
      {
        path: "/category",
        name: "CategoryList",
        meta: {
          title: "Category List",
        },
        component: () => import("../components/category/List"),
      },
      {
        path: "/category/add",
        name: "AddCategory",
        meta: {
          title: "Add Category",
        },
        component: () => import("../components/category/Add"),
      },
      {
        path: "/category/edit/:id",
        name: "EditCategory",
        meta: {
          title: "Edit Category",
        },
        component: () => import("../components/category/Edit"),
      },
      {
        path: "/rooms",
        name: "Rooms",
        meta: {
          title: "Rooms",
        },
        component: () => import("../components/booth/Rooms"),
      },
      {
        path: "/room/:room_id",
        name: "RoomsDetails",
        meta: {
          title: "Rooms Details",
        },
        component: () => import("../components/booth/Assignment"),
      },
      {
        path: "/representative",
        name: "RepresentativeList",
        meta: {
          title: "Representative List",
        },
        component: () => import("../components/representative/List"),
      },
      {
        path: "/helpdesk",
        name: "Helpdesk",
        meta: {
          title: "Helpdesk",
        },
        component: () => import("../components/helpdesk/List"),
      },
      {
        path: "/notification",
        name: "Notification",
        meta: {
          title: "Notification",
        },
        component: () => import("../components/notification/List"),
      },
      {
        path: "/file",
        name: "FileUploader",
        meta: {
          title: "FileUploader",
        },
        component: () => import("../components/file-uploader/List"),
      },
      {
        path: "/file/add",
        name: "FileUploader",
        meta: {
          title: "FileUploader",
        },
        component: () => import("../components/file-uploader/Add"),
      },
      {
        path: "/setting",
        name: "Settings",
        meta: {
          title: "Settings",
        },
        component: () => import("../components/Setting"),
      },
      {
        path: "/email-template",
        name: "EmailTemplate",
        meta: {
          title: "EmailTemplate",
        },
        component: () => import("../components/EmailTemplate"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/forgot-password",
    "/reset-password/" + to.params.id + "/" + to.params.token,
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = JSON.parse(localStorage.getItem("recongress_admin"));
  document.title = to.meta.title
  if (authRequired && !loggedIn) {
    next("/login");
  } else if (!authRequired && loggedIn) {
    next("/dashboard");
  } else {
    next();
  }
});
export default router;
