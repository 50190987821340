<template>
  <v-container>
    <v-card class="elevation-2  mx-auto" :loading="loading" max-width="600">
      <v-card-title>Update Profile</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form>
          <v-text-field v-model="name" :error-messages="form_error.name" label="Name" outlined dense></v-text-field>
          <v-text-field v-model="username" :error-messages="form_error.username" label="Username" outlined dense></v-text-field>
          <v-text-field v-model="email" :error-messages="form_error.email" label="E-mail" disabled  outlined dense></v-text-field>
          <v-btn class="mr-4" color="secondary" @click="updateProfile">submit</v-btn>
        </form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      form_error: {},
      file: "",
      showPreview: false,
      imagePreview: "",
      user_data: null,
      username: null,
      name: null,
      email: null,
      loading: false,
      data: {},
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    updateProfile() {
      let _self = this;
      this.loading = "secondary";
      this.$axios.post(`admin/update_profile`, {
        username: this.username,
        name: this.name,
      })
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            this.data = response_data.user_data;
            localStorage.setItem(
              "recongress_admin",
              JSON.stringify(response_data.user_data)
            );
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            this.user_data = response_data.user_data;
            _self.$router.push("/vendor");
            // window.location.href = '/dashboard'
            // _self.$router.push('/dashboard')
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              console.log(errors[key]);
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.user_data = JSON.parse(localStorage.getItem("recongress_admin"));
    this.username = this.user_data.username;
    this.name = this.user_data.name;
    this.email = this.user_data.email;
  },
};
</script>