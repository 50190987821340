<template>
  <v-app id="inspire" class="grey lighten-4">
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" color="white" app>
      <v-list dense>
        <template v-for="item in items">
          <v-list-item :key="item.text" :to="item.link" link color="secondary">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      dark
      dense
      class="elevation-5"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 550px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">RECongress Admin</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu :close-on-content-click="closeOnClick" :nudge-width="200" offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon large v-bind="attrs" v-on="on">
            <v-avatar color="black" size="36">
              <span class="white--text headline">A</span>
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar color="black" size="36">
                  <span class="white--text headline">A</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-if="user_data">{{user_data.username}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item to="/profile">
              <v-icon>mdi-account-cog</v-icon>
              <v-list-item-title class="ml-2">Profile</v-list-item-title>
            </v-list-item>
            <v-list-item to="/change-password">
              <v-icon>mdi-lock-outline</v-icon>
              <v-list-item-title class="ml-2">Change password</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-icon>mdi-logout</v-icon>
              <v-list-item-title class="ml-2">Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <Alert />
  </v-app>
</template>

<script>
import Alert from "@/components/Alert.vue";
export default {
  props: {
    source: String,
  },
  name: "App",
  components: {
    Alert,
  },
  data: () => ({
    dialog: false,
    drawer: null,
    loading: false,
    user_data: null,
    closeOnClick: true,
    items: [
      // { icon: "mdi-home", text: "Dashboard", link: "/dashboard" },
      { icon: "mdi-account", text: "Vendor Management", link: "/vendor" },
      {
        icon: "mdi-account-group",
        text: "Attendee Management",
        link: "/attendees",
      },
      {
        icon: "mdi-badge-account-outline",
        text: "Assigned Representative",
        link: "/representative",
      },
      { icon: "mdi-image", text: "Object Management", link: "/object" },
      { icon: "mdi-view-list", text: "Category Management", link: "/category" },
      { icon: "mdi-home-assistant", text: "Booth Assignment", link: "/rooms" },
      { icon: "mdi-help-circle", text: "Helpdesk", link: "/helpdesk" },
      { icon: "mdi-bell-ring-outline", text: "Notification", link: "/notification" },
      { icon: "mdi-email-outline", text: "Email Template", link: "/email-template" },
      { icon: "mdi-cog", text: "Settings", link: "/setting" }
    ],
  }),
  methods: {
    logout() {
      // let _self = this
      // this.$axios.post(`admin/logout`)
      // .then((response) => {
      //   let response_data = response.data;
      //   if (response_data.status) {
      //     _self.categories = response_data.data;
      //   }
      //   _self.loading = false;
      // })
      // .catch((e) => {
      //   console.log(e);
      //   _self.loading = false;
      // });
      localStorage.removeItem("recongress_admin");
      this.$router.push("/login");
    },
  },
  mounted: function () {
    this.user_data = JSON.parse(localStorage.getItem("recongress_admin"));
  },
};
</script>